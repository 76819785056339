<!--
* @Description 用户支持-更新日志-详情
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top">
      <div class="titlecon">
        <p>{{ softwareData.name }}</p>
        <p>{{ softwareData.alias }}</p>
      </div>
    </div>
    <div class="con">
      <h1 class="updateTitle">
        <span>{{ list.logTitle }}</span
        >&nbsp;
        <span>（{{ list.updateTime }}）</span>
      </h1>
      <div class="content" style="white-space: pre-wrap">
        <div class="ql-editor" style="padding: 0" v-html="list.logContent" />
      </div>
    </div>
  </div>
</template>

<script>
import { userInsertApi, getSoftwareInfo } from "@/api/log.js";
export default {
  data() {
    return {
      list: {
        logTitle: "",
        updateTime: "",
        logContent: "",
      },
      softwareData: null,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    getSoftwareInfo() {
      getSoftwareInfo(this.list.softwareId).then((response) => {
        if (response.code == 200) {
          this.softwareData = response.data
        }
      });
    },

    getList() {
      userInsertApi(this.$route.query.id).then((response) => {
        this.list = response.data;
        this.getSoftwareInfo();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutContainer {
  background: #fff;
  .top {
    width: 100%;
    height: 400px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/userImg/EIcad-2@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .titlecon {
      width: 999px;
      line-height: 1.5;
      margin: 0 auto;
      padding-top: 144px;

      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #643b10;
    }
  }

  .con {
    width: 1200px;
    padding-bottom: 136px;
    margin: 0 auto;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1d1d1f;
    .updateTitle {
      font-size: 40px;
      padding: 48px 0;
    }

    .content {
      width: 1000px;
      height: 384px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1d1d1f;
      line-height: 64px;
      text-align: left;
      margin: 0 auto;
    }
  }
}
</style>